@font-face {
    font-family: "ProximaNova";
    font-style: normal;
    font-weight: 100;
    src: url("https://style.gsc.org.uk/fonts/ProximaNova-Light.eot");
    src: url("https://style.gsc.org.uk/fonts/ProximaNova-Light.eot?#iefix") format("embedded-opentype"),
        url("https://style.gsc.org.uk/fonts/ProximaNova-Light.woff2") format("woff2"),
        url("https://style.gsc.org.uk/fonts/ProximaNova-Light.woff") format("woff"),
        url("https://style.gsc.org.uk/fonts/ProximaNova-Light.ttf") format("truetype"),
        url("https://style.gsc.org.uk/fonts/ProximaNova-Light.svg#ProximaNova-Light") format("svg");
}

@font-face {
    font-family: "ProximaNova";
    font-style: normal;
    font-weight: 200;
    src: url("https://style.gsc.org.uk/fonts/ProximaNova-Semibold.eot");
    src: url("https://style.gsc.org.uk/fonts/ProximaNova-Semibold.eot?#iefix") format("embedded-opentype"),
        url("https://style.gsc.org.uk/fonts/ProximaNova-Semibold.woff2") format("woff2"),
        url("https://style.gsc.org.uk/fonts/ProximaNova-Semibold.woff") format("woff"),
        url("https://style.gsc.org.uk/fonts/ProximaNova-Semibold.ttf") format("truetype"),
        url("https://style.gsc.org.uk/fonts/ProximaNova-Semibold.svg#ProximaNova-Semibold") format("svg");
}

@font-face {
    font-family: "ProximaNova";
    font-style: normal;
    font-weight: 400;
    src: url("https://style.gsc.org.uk/fonts/ProximaNova-Bold.eot");
    src: url("https://style.gsc.org.uk/fonts/ProximaNova-Bold.eot?#iefix") format("embedded-opentype"),
        url("https://style.gsc.org.uk/fonts/ProximaNova-Bold.woff") format("woff"),
        url("https://style.gsc.org.uk/fonts/ProximaNova-Bold.ttf") format("truetype"),
        url("https://style.gsc.org.uk/fonts/ProximaNova-Bold.svg#ProximaNova-Bold") format("svg");
}

@font-face {
    font-family: "ProximaNova";
    font-style: italic;
    font-weight: 100;
    src: url("https://style.gsc.org.uk/fonts/ProximaNova-LightIt.eot");
    src: url("https://style.gsc.org.uk/fonts/ProximaNova-LightIt.eot?#iefix") format("embedded-opentype"),
        url("https://style.gsc.org.uk/fonts/ProximaNova-LightIt.woff2") format("woff2"),
        url("https://style.gsc.org.uk/fonts/ProximaNova-LightIt.woff") format("woff"),
        url("https://style.gsc.org.uk/fonts/ProximaNova-LightIt.ttf") format("truetype"),
        url("https://style.gsc.org.uk/fonts/ProximaNova-LightIt.svg#ProximaNova-LightIt") format("svg");
}

@font-face {
    font-family: "ProximaNova";
    font-style: italic;
    font-weight: 200;
    src: url("https://style.gsc.org.uk/fonts/ProximaNova-SemiboldIt.eot");
    src: url("https://style.gsc.org.uk/fonts/ProximaNova-SemiboldIt.eot?#iefix") format("embedded-opentype"),
        url("https://style.gsc.org.uk/fonts/ProximaNova-SemiboldIt.woff2") format("woff2"),
        url("https://style.gsc.org.uk/fonts/ProximaNova-SemiboldIt.woff") format("woff"),
        url("https://style.gsc.org.uk/fonts/ProximaNova-SemiboldIt.ttf") format("truetype"),
        url("https://style.gsc.org.uk/fonts/ProximaNova-SemiboldIt.svg#ProximaNova-SemiboldIt") format("svg");
}
